"use client";
import { useEffect } from "react";
import { productsImpressionEvent } from "@/lib/gtm/dataLayerEvent";
import { RESULT_PER_PAGE } from "@/constants";
import { usePathname, useSearchParams } from "next/navigation";

export default function ProductsImpression({ products }) {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    const triggerProductImpess = () => {
      const page = searchParams.get("p") || 1;
      const query = searchParams.get("q");
      const perPage = RESULT_PER_PAGE;

      const listName =
        pathname === "/search" ? `Site Search: ${query}` : location.pathname.replace("/", "").split("/").join(",");
      productsImpressionEvent(products, listName, page, perPage);
    };
    triggerProductImpess();
  }, []);
  return <></>;
}
