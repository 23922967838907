"use client";
import { useEffect, useState } from "react";
import IconArrowDown from "@/components/Icon/IconArrowDown.svg";
import IconCheck from "@/components/Icon/IconCheck.svg";
import IconClose from "@/components/Icon/IconClose.svg";
import IconFilter from "@/components/Icon/IconFilter.svg";
import PositionDialog from "@/components/Dialog/PositionDialog";
import Switcher from "@/components/Switcher";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { capitalize, getUrl } from "@/utils/common";
import Link from "next/link";
import Image from "next/image";

export default function FilterMenuDialog({ products, totalBestBuys, pathName, searchParams, ...props }) {
  const { totalProducts } = props.totalProducts || 0;
  const filterMenu = props.filterMenu || [];
  const [showFilterDialog, setShowFilterDialog] = useState(false);

  const getHrefToResetFilter = () => {
    const params = {};
    if (searchParams.p) params.p = searchParams.p;
    if (searchParams.sortBy) params.q = searchParams.sortBy;
    return getUrl(pathName, params);
  };

  const getHref = (filterIndex, itemIndex) => {
    const list = [...filterMenu[filterIndex].list];

    const params = { ...searchParams };
    delete params.p;
    const selected = list.filter(subItem => subItem.selected);

    if (list[itemIndex].selected) {
      params[filterMenu[filterIndex].name] = selected
        .filter(subItem => subItem.name !== list[itemIndex].name)
        .map(subItem => subItem.name);
    } else {
      params[filterMenu[filterIndex].name] = selected.map(subItem => subItem.name).concat(list[itemIndex].name);
    }

    return getUrl(pathName, params);
  };

  const getBestBuysLink = () => {
    const params = { ...searchParams };
    if (params.best_buys === "true") {
      delete params.best_buys;
    } else {
      params.best_buys = true;
    }

    return getUrl(pathName, params);
  };

  return (
    <>
      <div
        className="w-1/2 flex justify-center lg:justify-between items-center gap-4 px-6 cursor-pointer border-r-0 lg:border-r-[1px] border-grey-100"
        onClick={() => setShowFilterDialog(true)}
      >
        <p className="font-bold text-sm">Filter</p>
        <Image src={IconFilter.src} alt="" width="12" height="12" />
      </div>
      <PositionDialog open={showFilterDialog} onChange={data => setShowFilterDialog(data)} position="right">
        <div className="dialog__content right">
          <div
            className={classNames(
              "max-w-[375px] lg:max-w-none w-screen lg:w-[456px] h-screen bg-white text-black border-r-[1px] border-grey-100 flex flex-col font-roboto  pb-[90px] md:pb-0"
            )}
          >
            <div className="w-full h-fit flex items-center px-[16px] md:px-8 py-4 border-b-[1px] border-grey-100">
              <p className="font-bold text-[26px]">Filter</p>
              <Image src={IconFilter.src} alt="" width="18" height="18" className="ml-[14px]" />
              <Image
                src={IconClose.src}
                alt=""
                width="14"
                height="14"
                className="ml-auto cursor-pointer"
                onClick={() => setShowFilterDialog(false)}
              />
            </div>
            <div className={classNames("h-fit lg:h-full bg-grey-50 overflow-y-auto ", styles.filterContent)}>
              <div className={classNames("bikebiz-accordion flex flex-col gap-2 mt-1 bg-white", styles.accordion)}>
                <details>
                  <summary className="w-full flex  justify-between text-[18px] font-bold relative py-4 md:py-5 px-[16px] lg:px-8">
                    <div className="w-full flex gap-1 text-lg font-bold relative cursor-pointer">
                      <div className="relative flex gap-1 text-lg font-bold">
                        Applied Filters
                        <div className="w-5 h-5 flex justify-center items-center bg-green rounded-full text-white text-[13px] font-bold absolute -right-6 -top-1">
                          {filterMenu.reduce((acc, item) => {
                            if (item && item.list) {
                              return acc + item.list.filter(subItem => subItem.selected).length;
                            } else {
                              return acc;
                            }
                          }, 0)}
                        </div>
                      </div>
                      <div className="absolute right-0 top-1/5 -translate-y-1/2">
                        <Image src={IconArrowDown.src} width="10" height="10" alt="" />
                      </div>
                    </div>
                  </summary>

                  <div
                    className={classNames("flex flex-col gap-1 px-[16px] lg:px-8 -mt-1 pb-2 md:pb-4", styles.content)}
                  >
                    {filterMenu.filter(item => item.list && item.list.some(subItem => subItem.selected)).length > 0 && (
                      <div className="flex flex-col gap-4 relative pt-3">
                        <div className="flex justify-between absolute right-0">
                          <Link href={getHrefToResetFilter()}>
                            <span className="text-sm cursor-pointer hover:underline">Clear all</span>
                          </Link>
                        </div>
                        {filterMenu.map(
                          (filterItem, i) =>
                            filterItem.list.find(subItem => subItem.selected) && (
                              <div key={`filter-menu-dialog__filter-item-${i}`} className="w-full flex flex-col">
                                <span className="text-base font-bold capitalize">{filterItem.name}</span>
                                <div className="w-full flex flex-col">
                                  {filterItem.list &&
                                    filterItem.list.map(
                                      (item, j) =>
                                        item.selected && (
                                          <div
                                            key={`filter-menu-dialog__dialog__filter-item-${i}__child-${j}`}
                                            className={classNames(
                                              "w-full flex justify-between items-center py-[10px]",
                                              j !== 0 ? " border-t-[1px] border-grey-100" : ""
                                            )}
                                          >
                                            <p className="text-sm capitalize">
                                              {capitalize(item.name.replaceAll("_", " "))}
                                            </p>
                                            <Link href={getHref(i, j, true)}>
                                              <Image src={IconClose.src} alt="" width="11" height="11" />
                                            </Link>
                                          </div>
                                        )
                                    )}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    )}
                  </div>
                </details>
              </div>
              {totalBestBuys > 0 && (
                <div className="w-full py-3 px-[16px] lg:px-8 bg-white mt-1">
                  <div className={classNames(styles.bestBuysBanner)}>
                    <p className="text-base leading-6 font-[500] text-white ml-2">Bikebiz Best Buys</p>
                    <p className="text-xs leading-[22px] font-bold mr-auto text-white">({totalBestBuys})</p>
                    <Link href={getBestBuysLink()}>
                      <Switcher size="large" thumbColor="green" value={searchParams.best_buys === "true"} />
                    </Link>
                  </div>
                </div>
              )}

              {filterMenu.map((filterItem, i) => (
                <div
                  key={`filter-by-${filterItem.name}-${i}`}
                  className={classNames("bikebiz-accordion flex flex-col gap-2 mt-1 bg-white", styles.accordion)}
                >
                  <details>
                    <summary className="w-full flex  justify-between text-[18px] font-bold relative capitalize cursor-pointer py-4 md:py-5 px-[16px] lg:px-8">
                      {filterItem.name}
                      <div className="absolute right-4 md:right-8 top-1/2 -translate-y-1/2">
                        <Image src={IconArrowDown.src} width="10" height="10" alt="" />
                      </div>
                    </summary>

                    <div
                      className={classNames("flex flex-col gap-1 px-[16px] lg:px-8 -mt-1 pb-2 md:pb-4", styles.content)}
                    >
                      {filterItem.list &&
                        filterItem.list.map((item, j) => (
                          <Link
                            key={`filter-by-category-${j}`}
                            className={classNames(
                              "py-4 pl-6 pr-4 flex items-center cursor-pointer",
                              item.selected ? "bg-black text-white" : "bg-grey-200 "
                            )}
                            href={getHref(i, j)}
                          >
                            <p className="font-[500] text-base capitalize">
                              {capitalize(item.name.replaceAll("_", " "))}
                            </p>
                            <span className="font-bold text-xs ml-2">({item.count})</span>
                            {item.selected && (
                              <div className="w-6 h-6 flex rounded-full bg-gold ml-auto items-center justify-center">
                                <Image src={IconCheck.src} alt="" width="16" height="16" />
                              </div>
                            )}
                          </Link>
                        ))}
                    </div>
                  </details>
                </div>
              ))}
            </div>
            <div className="flex mt-0 md:mt-auto py-4 md:py-6 px-1 md:px-3 border-t-[1px] border-grey-100">
              <div className="w-1/2 px-3 ">
                <Link href={getHrefToResetFilter()}>
                  <button className="w-full h-[48px] px-1 md:px-3 font-bold border-[1px] border-black">
                    Reset filters
                  </button>
                </Link>
              </div>
              <div className="w-1/2 px-3 ">
                <button
                  className="w-full h-[48px] px-1 md:px-3 font-bold border-[1px] border-gold bg-gold"
                  onClick={() => setShowFilterDialog(false)}
                >
                  Show {totalProducts} Results
                </button>
              </div>
            </div>
          </div>
        </div>
      </PositionDialog>
    </>
  );
}
