"use client";
import Image from "next/image";
import { useEffect, useState } from "react";
import PartsFinderDialog from "@/components/PartsFinderDialog";
import IconCog from "@/components/Icon/IconCog.svg";
import { getAccessoriesQuery, setAccessoriesQuery } from "@/utils/common";

export default function PartsFinder({ vehicle, ...props }) {
  const [showPartsFinderDialog, setShowPartsFinderDialog] = useState(false);

  const handleScroll = () => {
    const finderPart = document.getElementById("finder-part");
    const distanceToBottom = document.getElementById("filter-bar").getBoundingClientRect().bottom;
    const productsGrid = document.getElementById("product-grid");

    if (distanceToBottom <= 0) {
      finderPart.classList.add("fixed");
      finderPart.classList.add("bg-black");
      finderPart.classList.add("finder-sticky");
      productsGrid.style.marginTop = `${finderPart.offsetHeight}px`;
    } else {
      finderPart.classList.remove("fixed");
      finderPart.classList.remove("bg-black");
      finderPart.classList.remove("finder-sticky");
      productsGrid.style.marginTop = `0`;
    }
  };

  useEffect(() => {
    const currentVehicle = getAccessoriesQuery();
    if (vehicle) {
      setAccessoriesQuery({
        vehicleNumber: vehicle.vehicleNumber,
        histories: [
          ...currentVehicle.histories,
          {
            id: vehicle.id,
            name: vehicle.name,
            make: vehicle.make,
            model: vehicle.model,
            year: vehicle.year,
            vehicleNumber: vehicle.vehicleNumber,
          },
        ],
      });
    }
  }, [vehicle]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        id="finder-part"
        className="w-full top-0 z-20 cursor-pointer mb-10"
        onClick={() => setShowPartsFinderDialog(true)}
      >
        <div className="container-lg mx-auto">
          <div className="px-4">
            <div className=" bg-black rounded px-6 py-[18px] flex flex-col md:flex-row gap-3 md:gap-6 items-center content">
              <Image src={IconCog.src} alt="" width="40" height="40" className="icon-cog" />
              <div>
                <p className="text-[26px] leading-[26px] text-white font-bold  text-center md:text-start heading font-rajdhani">
                  Bikebiz Parts Finder
                </p>
                {vehicle ? (
                  <div className="text-sm leading-[20px] text-white  text-center md:text-start mt-[2px] md:mt-0 text font-roboto">
                    Currently shopping for
                    <span className="text-sm  leading-[20px] text-gold font-bold ml-1">
                      {vehicle.year} {vehicle.make} {vehicle.model}
                    </span>
                  </div>
                ) : (
                  <p className="text-sm leading-[20px] text-white  text-center md:text-start mt-[2px] md:mt-0 text">
                    The below products may be vehicle-specific. Search your motorcycle to shop what fits.
                  </p>
                )}
              </div>
              {vehicle ? (
                <button className="button h-[40px] px-6 flex justify-center items-center border-[1px] border-whote text-white font-bold font-roboto rounded-full ml-0 md:ml-auto">
                  Search different motorcycle
                </button>
              ) : (
                <button className="button h-[40px] px-6 flex justify-center items-center bg-gold font-bold font-roboto rounded-full ml-0 md:ml-auto mt-2 md:mt-0">
                  Search your motorcycle
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <PartsFinderDialog
        open={showPartsFinderDialog}
        onOpenDialog={data => setShowPartsFinderDialog(data)}
        vehicle={vehicle}
      />
    </>
  );
}
