"use client";
import { useState } from "react";
import IconArrowDown from "@/components/Icon/IconArrowDown.svg";
import IconCheck from "@/components/Icon/IconCheck.svg";
import IconClose from "@/components/Icon/IconClose.svg";
import PositionDialog from "@/components/Dialog/PositionDialog";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { getUrl } from "@/utils/common";
import Link from "next/link";
import Image from "next/image";

const sortByOptions = [
  {
    value: "title:asc",
    label: "Name: A to Z",
  },
  {
    value: "title:desc",
    label: "Name: Z to A",
  },
  {
    value: "price:asc",
    label: "Price: Low to High",
  },
  {
    value: "price:desc",
    label: "Price: High to Low",
  },
  {
    value: "bayesianAverageRating:asc",
    label: "Rating: Lowest first",
  },
  {
    value: "bayesianAverageRating:desc",
    label: "Rating: Highest first",
  },
];

export default function SortBy({ pathName, searchParams }) {
  let sortBy;

  const [showSortByDialog, setShowSortByDialog] = useState(false);

  if (searchParams.sort) {
    const temp = searchParams.sort.split("-");
    if (temp.length > 1) {
      sortBy = `${temp[1]}:desc`;
    } else {
      sortBy = `${temp[0]}:asc`;
    }
  }

  const getSortHref = sortBy => {
    const temp = sortBy.split(":");
    const operator = temp[1] === "asc" ? "" : "-";
    const params = { ...searchParams, sort: `${operator}${temp[0]}` };
    return getUrl(pathName, params);
  };

  return (
    <>
      <div
        className="w-1/2 border-r-[1px] border-grey-100 flex justify-center lg:justify-between items-center gap-4 px-6 cursor-pointer"
        onClick={() => setShowSortByDialog(true)}
      >
        <p className="font-bold text-sm">Sort by</p>
        <Image src={IconArrowDown.src} alt="IconArrowDown" width="14" height="9" />
      </div>
      <PositionDialog open={showSortByDialog} onChange={data => setShowSortByDialog(data)} position="right">
        <div
          className={classNames(
            "max-w-[375px] lg:max-w-none w-screen lg:w-[456px] h-screen bg-white text-black border-r-[1px] border-grey-100 flex flex-col font-roboto pb-[90px] lg:pb-0"
          )}
        >
          <div className="w-full h-fit flex items-center px-[16px] md:px-8 py-4 border-b-[1px] border-grey-100">
            <p className="font-bold text-[26px]">Sort By</p>

            <Image src={IconArrowDown.src} alt="IconArrowDown" width="18" height="18" className="ml-[14px]" />
            <Image
              src={IconClose.src}
              alt=""
              width="14"
              height="14"
              className="ml-auto cursor-pointer"
              onClick={() => setShowSortByDialog(false)}
            />
          </div>
          <div
            className={classNames(
              "h-git md:h-full bg-grey-50 px-[16px] md:px-8 py-4 md:py-6 flex flex-col gap-1 overflow-y-auto",
              styles.content
            )}
          >
            {sortByOptions.map((item, i) => (
              <Link
                key={`filter-${i}`}
                href={getSortHref(item.value)}
                className={classNames(
                  "py-4 pl-6 pr-4 flex items-center cursor-pointer",
                  item.value === sortBy ? "bg-black text-white" : "bg-grey-200 "
                )}
              >
                <p className="font-[500] text-base">{item.label}</p>
                {item.value === sortBy && (
                  <div className="w-6 h-6 flex rounded-full bg-gold ml-auto items-center justify-center">
                    <Image src={IconCheck.src} alt="" width="116" height="16" className="m-auto" />
                  </div>
                )}
              </Link>
            ))}
          </div>
          <div className="flex mt-0 md:mt-auto py-2 md:py-6 px-2 md:px-3 border-t-[1px] border-grey-100">
            <button
              className="w-full h-[48px] px-3 font-bold border-[1px] border-gold bg-gold"
              onClick={() => setShowSortByDialog(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </PositionDialog>
    </>
  );
}
