"use client";

import { RESULT_PER_PAGE } from "@/constants";
import { pushOnClickCatalogList } from "@/lib/gtm/events";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

export default function ProductClick({ product = {}, index, children }) {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const handleProductClicked = e => {
    const page = searchParams.get("p") || 1;
    const query = searchParams.get("q");
    const actualPosition = (page - 1) * RESULT_PER_PAGE + (index + 1); //Should +1 because position from zero-based
    const listName =
      pathname === "/search" ? `Site Search: ${query}` : location.pathname.replace("/", "").split("/").join(",");
    pushOnClickCatalogList(product, actualPosition, listName);
  };
  return (
    <div onClick={handleProductClicked} className="absolute w-full h-full ">
      {children}
    </div>
  );
}
